import axios from '@/utils/http'; // 导入http中创建的axios实例
import { inputJson, appID } from './baseParams'; // baseParams 默认的一些传参
import qs from 'qs'; // 根据需求是否导入qs模块
// let api
// let AIapi = "http://8.134.61.88:9001/"
// let DrawingTestApi
// let DrawingApi
// let DrawingApi2
let sessionUrl = sessionStorage.getItem("url") ? JSON.parse(sessionStorage.getItem("url")) : ""
let api = sessionUrl.api ? sessionUrl.api : ""
let DrawingTestApi = sessionUrl.DrawingTestApi ? sessionUrl.DrawingTestApi : ""
let DrawingApi = sessionUrl.DrawingApi ? sessionUrl.DrawingApi : ""
let DrawingApi2 = sessionUrl.DrawingApi2 ? sessionUrl.DrawingApi2 : ""

//要引入axios才能用
//注意获取config.json的地址
axios.get("./config.json").then((result) => {
  console.log(result.data)
  let axiosUrl = result.data
  sessionStorage.setItem("url", JSON.stringify(axiosUrl))
  api = axiosUrl.api
  DrawingTestApi = axiosUrl.DrawingTestApi
  DrawingApi = axiosUrl.DrawingApi
  DrawingApi2 = axiosUrl.DrawingApi2
}).catch((error) => { console.log(error) });

const beyoudsky = {
  //根据授权码获取微信用户信息https://api.beyondsky.love/saas/msg/api/Msg/GetWXScopeInfo
  GetWXScopeInfo(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`https://api.beyondsky.love:4433/saas/msg/api/Msg/PartnerRegister`, qs.stringify(param));
    // return axios.post(`${DrawingTestApi}msg/api/Msg/GetWXScopeInfo`, qs.stringify(param));
  },
  //根据微信ID获取用户信息http://8.134.61.88:11084/api/Sign/GetUserPoByWX
  GetUserPoByWX(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}api/Sign/GetUserPoByWX`, qs.stringify(param));
  },
  //获取所有的问答类型
  GetQATypes(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/MsgConfig/GetQATypes`, qs.stringify(param));
  },
  //新增一条问答记录https://api.beyondsky.love/saas/msg/api/Msg/AddMsgHistory
  AddMsgHistory(inputJson) {
    return axios.post(`${DrawingTestApi}msg/api/Msg/AddMsgHistory`, inputJson);
  },
  //注册https://api.beyondsky.love/saas/user/api/Sign/UpdateWXUserInfo
  UpdateWXUserInfo(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}user/api/Sign/UpdateWXUserInfo`, qs.stringify(param));
  },
  //获取问题的回复https://api.beyondsky.love/saas/msg/api/Msg/GetQAAnswer
  GetQAAnswer(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`https://api.beyondsky.love:4433/saas/msg/api/Msg/GetQAAnswer`, qs.stringify(param));
    // return axios.post(`${DrawingTestApi}msg/api/Msg/GetQAAnswer`, qs.stringify(param));
  },
  //评价问题满意或不满意https://api.beyondsky.love/saas/msg/api/MsgConfig/EvaluateQA
  EvaluateQA(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/MsgConfig/EvaluateQA`, qs.stringify(param));
  },
  //顾问回答https://api.beyondsky.love/saas/msg/api/Msg/AnswerTheQuestion
  AnswerTheQuestion(inputJson) {
    return axios.post(`${DrawingTestApi}msg/api/Msg/AnswerTheQuestion`, inputJson);
  },
  //发送验证码https://api.beyondsky.love/saas/msg/api/Msg/SendVerify
  SendVerify(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Msg/SendVerify`, qs.stringify(param));
  },
  //获取是否可以回复并更新问题状态（回复中）https://api.beyondsky.love/saas/msg/api/MsgConfig/GetQAStatus
  GetQAStatus(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`https://api.beyondsky.love:4433/saas/msg/api/MsgConfig/GetQAStatus`, qs.stringify(param));
    // return axios.post(`${DrawingTestApi}msg/api/MsgConfig/GetQAStatus`, qs.stringify(param));
  },
  //回复状态回退到待回复https://api.beyondsky.love/saas/msg/api/MsgConfig/UpdateQIsW
  UpdateQIsW(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/MsgConfig/UpdateQIsW`, qs.stringify(param));
  },
  //新增线索
  SaveClue(inputJson) {
    // let param = {}
    // param.InputJson = JSON.stringify(inputJson);
    // return axios.post(`${DrawingTestApi}msg/api/Clue/SaveClue`, qs.stringify(param));
    return axios.post(`${DrawingTestApi}msg/api/Clue/SaveClue`, inputJson);
  },
  //手机注册
  LoginByPhone(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}user/api/Sign/LoginByPhone`, qs.stringify(param));
  },

  //扫码登录获取用户信息
  LoginByScanCode(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}user/api/Sign/LoginByScanCode`, qs.stringify(param));
  },
  //轮询获取扫码登录者信息
  RandomGetUserInfo(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}user/api/Sign/RandomGetUserInfo`, qs.stringify(param));
  },


  //根据联系人id获取联系人详细信息https://api.beyondsky.love/saas/msg/api/MsgConfig/GetContactByCid
  GetContactByCid(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`https://api.beyondsky.love:4433/saas/msg/api/MsgConfig/GetContactByCid`, qs.stringify(param));
  },
  // 获取后台用户列表https://api.beyondsky.love/saas/user/api/Sign/GetAllDocuvixList
  GetAllDocuvixList(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`https://api.beyondsky.love:4433/saas/user/api/Sign/GetAllDocuvixList`, qs.stringify(param));
  },
  // 修改联系人信息https://api.beyondsky.love/saas/msg/api/Clue/UpdateContractMsg
  UpdateContractMsg(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Clue/UpdateContractMsg`, qs.stringify(param));
  },
  // 获取问答记录接口
  //api.beyondsky.love/saas/msg/api/Msg/GetMsgHisList
  GetMsgHisList(inputJson, PageSize, PageIndex) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    param.PageSize = PageSize;
    param.PageIndex = PageIndex;
    return axios.post(`${DrawingTestApi}msg/api/Msg/GetMsgHisList`, qs.stringify(param));
  },
  //联系人分发//api.beyondsky.love/saas/msg/api/Clue/UpdateContract
  UpdateContract(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Clue/UpdateContract`, qs.stringify(param));
  },
  // 新增线索跟进记录https://api.beyondsky.love/saas/msg/api/Clue/SaveClueRecord
  SaveClueRecord(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Clue/SaveClueRecord`, qs.stringify(param));
  },
  // 修改线索信息https://api.beyondsky.love/saas/msg/api/Clue/UpdateClueMsg
  UpdateClueMsg(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Clue/UpdateClueMsg`, qs.stringify(param));
  },
  // 根据线索id获取跟进列表和跟进数https://api.beyondsky.love/saas/msg/api/Clue/GetClueRecode
  GetClueRecode(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Clue/GetClueRecode`, qs.stringify(param));
  },
  // 获取是否关注公众号https://api.beyondsky.love/saas/user/api/Sign/GetWXUserInfo
  GetWXUserInfo(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}user/api/Sign/GetWXUserInfo`, qs.stringify(param));
  },
  // 轮训获取扫码登录者信息https://api.beyondsky.love/saas/msg/api/MsgTask/RandomGetUserInfo
  //获取轮播图配置
  GetRotationPos(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`https://api.beyondsky.love:4433/saas/msg/api/Msg/GetRotationPos`, qs.stringify(param));
  },
  //获取活动详情配置
  GetRotationDetialPos(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`https://api.beyondsky.love:4433/saas/msg/api/Msg/GetRotationDetialPos`, qs.stringify(param));
  },
  //登记公司报名
  AddRegistration(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Msg/AddRegistration`, qs.stringify(param));
  },


  //新增埋点
  AddAction(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Msg/AddAction`, qs.stringify(param));
  },






  //登录接口 
  login(inputJson) {
    inputJson.AppID = appID
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/User/LoginNoCode`, qs.stringify(param));
  },
  //获取验证码接口
  Valid() {
    return axios.post(`${api}user/User/GetCode`);
  },
  //获取用户的兼职组织
  GetAffiliation(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/GetAffiliation`, qs.stringify(param));
  },
  //获取全部的组织
  GetOrgList(inputJson, PageSize, PageIndex) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/GetOrgList?PageSize=${PageSize}&PageIndex=${PageIndex}`, qs.stringify(param));
  },
  //新增组织
  AddOrg(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/AddOrg`, qs.stringify(param));
  },
  //编辑组织
  UpdateOrg(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/UpdateOrg`, qs.stringify(param));
  },
  //删除组织
  DeleteOrg(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/DeleteOrg`, qs.stringify(param));
  },
  //新增用户
  AddAffiliation(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/AddAffiliation`, qs.stringify(param));
  },
  //编辑用户
  UpdateAffiliation(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/UpdateAffiliation`, qs.stringify(param));
  },
  //删除用户
  DelAffiliation(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/DelAffiliation`, qs.stringify(param));
  },
  //获取全部的用户
  GetUserList(inputJson, PageSize, PageIndex) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/GetUserList?PageSize=${PageSize}&PageIndex=${PageIndex}`, qs.stringify(param));
  },
  //修改密码
  UpdatePass(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/User/UpdatePass`, qs.stringify(param));
  },
  //获取所有用户列表(除了自己)【编辑界面下拉框用】
  GetAllUserList(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/Org/GetAllUserList`, qs.stringify(param));
  },
  //根据userid获取用户信息
  GetUserPoById(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${api}user/User/GetUserPoById`, qs.stringify(param));
  },

  //新增投诉https://api.beyondsky.love/saas/msg/api/Msg/AddComplain
  AddComplain(fd){
    return axios.post(`${DrawingTestApi}msg/api/Msg/AddComplain`, fd);
  },
  //查看投诉https://api.beyondsky.love/saas/msg/api/Msg/GetComplain
  GetComplain(inputJson){
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Msg/GetComplain`, qs.stringify(param));
  },
  //回复投诉https://api.beyondsky.love/saas/msg/api/Msg/UpdateComplain
  UpdateComplain(inputJson){
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingTestApi}msg/api/Msg/UpdateComplain`, qs.stringify(param));
  },
  //扫码关注公众号及登录
  GetLoginQRCode(){
    // return axios.post(`https://dslib.beyondsky.love/saas/dkwswechat/api/GetLoginQRCode`);
    return axios.post(`https://docuvix.com/saas/dkwswechat/api/GetLoginQRCode`);
  },
  CheckLoginInfoByTicket(inputJson){
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`https://api.beyondsky.love:4433/saas/dsarchive/api/Third/CheckLoginInfoByTicket`, qs.stringify(param));
  }
}

const DrawingIdentification = {
  //更新关联树数据
  UpdateTree(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/UpdateTree`, qs.stringify(param));
    // return axios.post(`http://8.134.61.88:11075/api/Temp/GetItemByTID`, qs.stringify(param));
  },
  //关联关系树用获取模板字段列表
  GetItemByTID(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/Temp/GetItemByTID`, qs.stringify(param));
    // return axios.post(`http://8.134.61.88:11075/api/Temp/GetItemByTID`, qs.stringify(param));
  },
  //获取所有的模板
  getTemplateList(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    console.log(DrawingTestApi)
    return axios.post(`${DrawingTestApi}api/UF_GetTemplateList`, qs.stringify(param));
  },
  //上传文件
  uploadFile(inputJson) {
    return axios.post(`${DrawingApi}api/AiDistin/FileTrans`, inputJson);
  },
  //获取订单数据
  getHistoryOrder(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/GetOrder`, qs.stringify(param));
  },
  //根据状态查询历史订单记录详情
  getOrderHistory(inputJson, PageSize, PageIndex) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/GetOrderHistory?PageSize=${PageSize}&PageIndex=${PageIndex}`, qs.stringify(param));
  },
  //查询各个状态以及对应的历史订单记录数量
  getOrderNum(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/GetHistoryStatus`, qs.stringify(param));
  },
  //分页查询订单
  getOrderLimit(inputJson, PageSize, PageIndex) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/GetOrderLimit?PageSize=${PageSize}&PageIndex=${PageIndex}`, qs.stringify(param));
  },
  //查询模板及字段接口
  getTemp(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/Temp/GetTemp`, qs.stringify(param));
  },
  //查询历史订单的识别结果
  getImageContent(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/GetImageContent`, qs.stringify(param));
  },
  //提交识别结果
  updateImageContent(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/UpdateImageContent`, qs.stringify(param));
  },
  //历史订单提交至关联关系状态
  submitHistoryOrder(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/SubmitHistoryOrder`, qs.stringify(param));
  },
  //识别异常-重新识别
  DistinReTry(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi2}api/DistinCustomer/DistinReTry`, qs.stringify(param));
  },
  //建立关联关系-退回识别中
  RejectHistoryOrder(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/RejectHistoryOrder`, qs.stringify(param));
  },
  //导出excel
  ExportExcel(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/ExportExcel`, qs.stringify(param));
  },
  //查看树状图
  ExportTree(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/ExportTree`, qs.stringify(param));
  },
  //获取当前用户可看的项目（订单）列表
  GetLink(inputJson, PageSize, PageIndex) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/Link/GetLink?PageSize=${PageSize}&PageIndex=${PageIndex}`, qs.stringify(param));
  },
  //编辑项目授权用户组
  UpdateLink(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/Link/UpdateLink`, qs.stringify(param));
  },
  //删除项目（订单）接口
  DeleteOrder(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/DeleteOrder`, qs.stringify(param));
  },
  //检查是否有权限
  CheckAuth(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/CheckAuth`, qs.stringify(param));
  },
  //删除任务
  DeleteOrderHistory(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/DeleteOrderHistory`, qs.stringify(param));
  },
  //根据主图编号获取图片
  GetImageByNo(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/GetImageByNo`, qs.stringify(param));
  },
  //删除图片
  DeleteImage(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/DeleteImage`, qs.stringify(param));
  },
  //识别已完成退回到正在识别中接口
  RejectOrderEnd(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`${DrawingApi}api/AiDistin/RejectOrderEnd`, qs.stringify(param));
  },
  GetViewMsg(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`http://112.74.163.215:28001/api/Borrow/GetViewMsg`, qs.stringify(param));
  },
  SaveViewMsg(inputJson) {
    let param = {}
    param.InputJson = JSON.stringify(inputJson);
    return axios.post(`http://112.74.163.215:28001/api/Borrow/SaveViewMsg`, qs.stringify(param));
  },

}
export default { beyoudsky, DrawingIdentification };



