<template>
  <!-- 引导页 -->
  <div id="guidepage" @mousewheel="scrollChange" ref="menu">
    <div class="mobilheader_box" style="text-align: left">
      <!--移动端导航-->
      <div ref="mobil_header" class="mobil_header" style="height: 1rem" :class="{ active: isActive }"
        :style="{ backgroundColor: $route.name == 'download' ? 'white' : '' }" v-show="$route.name !== 'register' && $route.name !== 'complain'">
        <div class="container header-container">
          <a href="http://docuvix.com/" style="position: absolute; left: 4%">
            <div class="logo-box">
              <img class="logo hidden-xs imghover" src="@/assets/images/logo.png" alt="道可维斯"
                :style="{ display: $route.name == 'download' ? 'block' : '' }" />
              <img class="logo visible-xs-block" src="@/assets/images/logo-product.png" alt="道可维斯"
                :style="{ display: $route.name == 'download' ? 'none' : '' }" />
            </div>
          </a>
          <div class="menu">
            <el-menu :default-active="activeIndex" v-if="menu1" class="el-menu-demo white" mode="horizontal"
              @select="handleSelect">
              <router-link to="/">
                <el-menu-item index="1" class="initial">首&nbsp;&nbsp;页</el-menu-item></router-link>
              <router-link to="/aboutMe">
                <el-menu-item index="2" class="initial">关于我们</el-menu-item></router-link>
              <a href="https://weibo.com/u/7821129224" target="_blank">
                <el-menu-item index="3" class="initial">创始人微博</el-menu-item></a>
              <!-- <el-menu-item index="3" class="initial" @click="chengzhang"
                ><router-link to="/">伙伴成长计划</router-link></el-menu-item
              > -->
            </el-menu>
            <el-menu :default-active="activeIndex" v-if="menu2" class="el-menu-demo" mode="horizontal"
              @select="handleSelect">
              <router-link to="/">
                <el-menu-item index="1">首&nbsp;&nbsp;页</el-menu-item></router-link>
              <router-link to="/aboutMe">
                <el-menu-item index="2">关于我们</el-menu-item></router-link>
              <a href="https://weibo.com/u/7821129224" target="_blank">
                <el-menu-item index="3" class="initial" style="color: black !important">创始人微博</el-menu-item></a>
              <!-- <el-menu-item index="3" @click="chengzhang"
                ><router-link to="/">伙伴成长计划</router-link></el-menu-item
              > -->
            </el-menu>
          </div>
          <!-- <span class="scanLogin" v-if="menu1" @click="scanlogin"
            >手机号<span style="color: #2788ee" @click="scanlogin"
              >登录</span
            ></span
          > -->
          <span class="scanLogin1" v-if="menu1">你好,{{ nickname != "" ? nickname : "游客 " }}
            <span style="color: #2788ee" @click="scanlogin1" v-show="nickname == ''">请登录</span>
          </span>
          <!-- <span
            class="scanLogin"
            style="color: #444"
            v-if="menu2"
            @click="scanlogin"
            >手机号<span style="color: #2788ee" @click="scanlogin"
              >登录</span
            ></span
          > -->
          <span class="scanLogin1" style="color: #444" v-if="menu2">你好 ,{{ nickname != "" ? nickname : "游客 " }}
            <span style="color: #2788ee" @click="scanlogin1" v-show="nickname == ''">请登录</span></span>
          <div class="features-box" v-if="offsetWidth < 1024" style="display: flex; align-items: center">
            <a href="javascript:;" class="gm" @click="openRegister">申请试用</a>
            <el-button @click="drawerNav = true" style="
                margin-left: 16px;
                border: none;
                background-color: rgb(0, 0, 0, 0);
                font-size: 20px;
              " class="el-icon-s-operation" :style="{ color: menu1 ? 'white' : 'black' }">
            </el-button>
          </div>
          <!-- <div class="features-box" v-if="offsetWidth>1024"> -->
          <a class="huoqu_more gm" href="javascript:;" style="
              display: inline-block;
              width: 200px;
              width: 98px;
              position: absolute;
              right: 10px;
              top: -20px;
              color: #fff;
            " v-if="offsetWidth > 1024" @click="openRegister">申请试用</a>
          <!-- </div> -->
        </div>
      </div>
      <el-drawer title="更多菜单" size="80%" ref="drawerNav" :visible.sync="drawerNav" :direction="direction"
        :before-close="handleNavClose">
        <div>
          <el-menu class="el-menu-vertical-demo" @select="handleSelect">
            <router-link to="/">
              <el-menu-item index="1">首&nbsp;&nbsp;页</el-menu-item></router-link>
            <router-link to="/aboutMe">
              <el-menu-item index="2">关于我们</el-menu-item></router-link>
            <a href="https://weibo.com/u/7821129224" target="_blank">
              <el-menu-item index="3" class="initial">创始人微博</el-menu-item></a>
          </el-menu>
        </div>
      </el-drawer>
      <router-view></router-view>

      <div class="advisory mobil_advisory">
        <!--<div class="advisory-box">
  
    <div class="advisory-item personal">
      <img class="advisory-icon" src="@/assets/mobile/v0.1/images/cont-icon1.png" alt="" >
      <div class="advisory-text-box">
        <p class="advisory-title">徐少春个人号</p>
        <p class="advisory-text">您可以与徐少春先生交流管理哲学与商业文明，也期待聆听您对于金蝶产品和服务的重要建议或反馈
        </p>
      </div>
    </div>
    
    <div class="advisory-item advisory-item-after">
      <img class="advisory-icon" src="@/assets/mobile/v0.1/images/cont-icon4_1.png" alt="">
      <div class="advisory-text-box">
        <p class="advisory-title">售后在线服务</p>
        <p class="advisory-text">为您的每一次提问，提供匹配的答案</p>
      </div>
    </div>
  </div>
  
  <div class="advisory-tips-box">
    <div class="advisory-tips-1">
      欢迎您的访问 ^-^
    </div>
    <div class="advisory-tips-2">
      戳戳我，快速答疑解惑哟~
    </div>
  </div>-->

        <div class="advisory-img-box">
          <a href="tel:400-6789-189">
            <div class="advisory-img">
              <img class="advisoryimg" src="@/assets/mobile/v0.1/images/mobil_header/phone.gif" alt="" />
            </div>
          </a>
        </div>
      </div>

      <div class="sales-intercept mask">
        <div class="sales-intercept-box">
          <div class="sales-intercept-close">
            <img src="@/assets/mobile/v0.1/images/mobil_header/close.png" alt="" />
          </div>
          <div class="sales-intercept-title">
            <div class="sales-intercept-icon">
              <img src="@/assets/mobile/v0.1/images/mobil_header/tips.png" alt="" />
            </div>
            <h3>抱歉，您所使用的账号暂未绑定对应的产品！</h3>
          </div>
          <div class="sales-intercept-describe">
            请联系贵司企业管理员，为您的账号绑定对应的产品。若需购买产品，
            请拨打
            <a class="number" href="javascript:;">400-6789-189</a>
            免费咨询
          </div>
          <a href="javascript:;">
            <div class="sales-intercept-butt">确定</div>
          </a>
        </div>
      </div>
      <!-- v-show="$route.name !== 'download'" -->
      <div class="Bottom-remain" :class="{ isBlock: isActive }">
        <a href="javascript:;" rel="nofollow" @click="openQuestion">
          <div class="Bottom-remain-left">
            <img src="@/assets/mobile/v0.1/images/mobil_header/zx_icon.png" />我要提问
          </div>
        </a>
        <a href="tel:400-6789-189" @click="hotline('咨询热线')">
          <div class="Bottom-remain-right">
            <img :style="{ 'margin-right': '5px' }" src="@/assets/mobile/v0.1/images/mobil_header/qianyue.png" />
            咨询热线
          </div>
        </a>
      </div>
    </div>

    <!-- 有疑问？我们将通过以下渠道为您解答 S -->
    <div class="index-yiwen" v-show="$route.name !== 'complain'">
      <div class="wrapper">
        <div class="info">
          <h2 class="fnt_32">如果想进一步了解道可维斯产品或解决方案</h2>
          <!-- <p class="fnt_20" v-if="offsetWidth > 1024">
            您可以通过以下方式联系我们，我们将推荐适合您需求的产品，或指派专业的销售顾问为您定制解决方案。
          </p>
          <p class="fnt_20" v-if="offsetWidth < 1024">
            您可以通过以下方式联系我们，我们将推荐适合您需求的产品，或指派专业的销售顾问为您定制解决方案。
          </p> -->
          <p class="fnt_20">
            您可以通过以下方式联系我们，我们将推荐适合您需求的产品，或指派专业的销售顾问为您定制解决方案。
          </p>
          <!-- <p
            style="
              text-align: center;
              margin: 0 auto;
              margin-top: 20px;
              max-width: 100%;
            "
            v-if="offsetWidth > 1024"
          >
            <span>
              <a
                class="huoqu_more"
                href="javascript:;"
                style="display: inline-block; width: 300px"
                @click="openRegister"
                >即刻联系我们，获取免费咨询</a
              ></span
            >
            &nbsp;&nbsp;&nbsp;
            <span
              ><a
                class="huoqu_more"
                href="javascript:;"
                style="display: inline-block; width: 300px"
                >电话热线： 400-6789-189</a
              ></span
            >
          </p> -->
          <!-- <div class="jd-info" > -->
          <!-- <div class="jd-info" v-if="offsetWidth < 1024">
            <div class="qrc">
              <img
                src="@/assets/mobile/v0.1/images/mobil_product_xc/code.png"
                alt=""
              />
            </div>
            <div class="txt fnt_20">
              扫码获取最新咨询<br />
              或致电：400-6789-189<br />
            </div>
          </div> -->
          <div class="jd-info" style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 10px;
            ">
            <div class="qrc">
              <img src="@/assets/mobile/v0.1/images/mobil_product_xc/code.png" alt="" />
            </div>
            <div class="txt fnt_20" style="margin: 10px">
              扫码获取最新咨询<br />
              或致电：400-6789-189<br />
            </div>
          </div>
        </div>
        <!-- <div class="img">
           <img
              src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/02114949j0l6.png"
              alt=""
            />
        </div> -->
      </div>
    </div>

    <div class="mobil_footer" style="text-align: left" v-show="$route.name !== 'complain'">
      <div class="footer-right" v-if="offsetWidth < 1024">
        <!-- <div class="footer-link-mobil">
          <a href="tel:400-6789-189">咨询热线: 400-6789-189</a>
        </div> -->
        <div class="footer-link-bottom">
          <div class="footer-link-bottombq">
            <span>CopyRight © 2009-2029 Docuvix All Rights Reserved</span>
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer"></a>
          </div>
          <div class="footer-link-bottomba">
            <!-- http://www.beian.gov.cn -->
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备13024405号</a>
          </div>
        </div>
      </div>
      <div class="footer-right" v-if="offsetWidth > 1024">
        <div class="footer-link-bottom">
          <div class="footer-link-bottombq">
            <span>CopyRight © 2009-2029 Docuvix All Rights Reserved</span>
            &nbsp;&nbsp;&nbsp;
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备13024405号</a>
          </div>
          <!-- <div class="footer-link-mobil">
            <a href="tel:400-6789-189">咨询热线: 400-6789-189</a>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 提问框 -->
    <el-drawer title="我是标题" :visible.sync="drawer" direction="btt" :with-header="false" class="drawer">
      <p :style="{ 'font-size': '16px', 'margin-bottom': '16px' }">
        尊敬的客户您好，您要咨询哪方面的问题
      </p>
      <div class="tag-group">
        <el-button v-for="item in questionTags" @click="routerToQuestion(item.label)" :key="item.label">
          {{ item.label }}
        </el-button>
        <!-- <el-tag
          v-for="item in questionTags"
          :key="item.label"
          :type="item.type"
          effect="plain"
        >
          {{ item.label }}
        </el-tag> -->
      </div>
    </el-drawer>

    <el-dialog title="我要提问" :visible.sync="dialogVisible" width="50%" class="drawer1">
      <p :style="{ 'font-size': '20px', 'margin-bottom': '30px' }">
        尊敬的客户您好，您要咨询哪方面的问题
      </p>
      <div class="tag-group">
        <el-button v-for="item in questionTags" @click="routerToQuestion(item.label)" :key="item.label">
          {{ item.label }}
        </el-button>
        <!-- <el-tag
          v-for="item in questionTags"
          :key="item.label"
          :type="item.type"
          effect="plain"
        >
          {{ item.label }}
        </el-tag> -->
      </div>
      <!-- <p class="msg" :style="{ 'font-weight': '700' }">
        请先选择您的提问类型：<el-select
          v-model="value1"
          placeholder="请选择"
          @change="changeQuestion"
        >
          <el-option
            v-for="(item, index) in optionsQues"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </p> -->

      <!-- <p class="msg">
        {{ questionMessage }}
      </p> -->
      <!-- <p class="tip">(工作人员将在24小时内，以微信公众号形式回复您)</p> -->
      <!-- <div class="input">
        <el-input type="textarea" resize="none" v-model="question"></el-input>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4}"
          placeholder="请输入您的问题"
          v-model="question"
          style="overflow-y:auto;"
        >
        </el-input>
        <el-button class="submit1" type="primary" @click="routerToQuestion(item.label)">提交</el-button>
      </div> -->
    </el-dialog>

    <div class="tip-right" v-if="offsetWidth > 1024">
      <a href="javascript:;" rel="nofollow" @click="openQuestion">
        <div class="Bottom-remain-left">
          <img src="@/assets/mobile/v0.1/images/mobil_header/qianyue.png" />
          <span>我要提问</span>
        </div>
      </a>
      <!-- <a href="javascript:;" @click="qidai">
        <div class="Bottom-remain-right">
          <img
            src="@/assets/mobile/v0.1/images/mobil_header/qianyue.png"
          />
          <span>立即签约</span> 
        </div>
      </a> -->
      <a href="javascript:;">
        <div class="Bottom-remain-bottom">
          <img src="@/assets/mobile/v0.1/images/mobil_header/qianyue.png" />
          <span class="Bottom-remain-bottom1">联系我们</span>
          <div class="connet-us">
            <div class="title">
              <span>官方售前热线咨询</span>
              <span>400-6789-189</span>
            </div>
            <div class="detial">
              <span class="erweima">
                <img src="../../assets/images/erweima.png" alt="" />
              </span>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- 手机号登录 -->
    <el-dialog title="手机号登录" :visible.sync="codeShow" width="30%">
      <div class="phoneLogin" style="text-align: center">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item label="手机号" prop="phoneNumber">
            <el-input type="text" placeholder="" name="phoneNum" id="phoneNum" ref="phoneNum" class="color"
              v-model="ruleForm.phoneNumber" />
          </el-form-item>
          <el-form-item label="验证码" prop="code" ref="code">
            <div class="ipt-item">
              <div class="input">
                <el-row>
                  <el-col :span="10"><el-input type="text" placeholder="" name="name" id="name" class="color"
                      v-model="ruleForm.code">
                    </el-input>
                  </el-col>
                  <el-col :span="10"><el-button class="codeBtn" :class="{ codeBtnClick: codeCd }" :disabled="codeCd"
                      @click="handleCaptcha('ruleForm')">
                      <span v-if="codeCd">{{ long }}s后可重发</span>
                      <span v-else>获取验证码</span>
                    </el-button></el-col>
                </el-row>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitFormRegister('ruleForm')" class="submitQuestion">
              提交注册
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 微信扫码登录 -->
    <!-- <el-dialog
      title=""
      :visible.sync="codeShow1"
      width="30%"
      >
      <div id="login_container" ref="wxQrcode" style="text-align:center;"></div> -->
    <!-- <el-dialog 
    title="微信扫码登录" 
    :visible.sync="qrCodeDialogVisible" 
    width="30%"  
    @opened="qrCodeCreate"  
    @close="qrCodeHandleClose">
      <div id="login_container" style="text-align: center"></div>
    </el-dialog> -->

    <el-dialog :visible.sync="qrCodeDialogVisible" :width="offsetWidth > 768 ? '30%' : '50%'" @opened="qrCodeCreate"
      :before-close="qrCodeHandleClose" @close="qrCodeHandleClose">
      <div>
        <!-- <div id="qrCode" ref="qrCode"></div> -->
        <div id="login_container" style="text-align: center"></div>
      </div>
      <!-- <span slot="footer" class="dialog-footer"> </span> -->
    </el-dialog>

    <el-dialog title="请先关注公众号" :visible.sync="officialAccountDialogVisible" :width="offsetWidth > 768 ? '20%' : '50%'"
      center>
      <div class="officialAccount">
        <div class="officialAccount_code">
          <img src="@/assets/mobile/v0.1/images/mobil_product_xc/code.png" alt="" style="width: 80%; height: 80%" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="openScanCodeLogin" plain size="small">我已关注</el-button>
      </span>
    </el-dialog>

    <el-dialog title="扫码登录" :visible.sync="loginVisiable" :width="offsetWidth > 768 ? '20%' : '50%'"
      @close="handleCloseLoginCode"
      center>
      <div class="officialAccount">
        <div class="officialAccount_code" v-loading="qrcodeLoading">
          <img :src="qrcode" alt="" style="width: 80%; height: 80%" />
        </div>
        <div class="my-2 text-center flex justify-center items-center" v-if="codeValidity">
          <span class="ml-2">扫码有效期：{{ second }} s</span>
          <!-- 请使用 <span class="text-szdak mx-2">微信APP</span> 扫一扫 -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// require("@/assets/mobile/v0.1/js/jquery-1.11.1.min.js");
// require("@/assets/mobile/v0.1/js/header.js");
import { slider, slideritem } from "vue-concise-slider";
// import GuidePage from "@views/GuidePage/index.vue";
//   import companyProfile from "./companyProfile.vue";
//   import caseCompany from "./case.vue";
//   import agreement from "./agreement.vue";
import { regEx } from "@/utils/regex";
import QRCode from "qrcodejs2";
export default {
  name: "Home",

  data() {
    //姓名验证
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    //手机号验证
    var validatePhoneNumber = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!regEx.mobile.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    //验证码验证
    var checkCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (value.length != 6) {
        callback(new Error("验证码格式不正确"));
      } else {
        callback();
      }
    };
    //问题验证
    var checkQuestion = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入您的问题或意见"));
      } else {
        callback();
      }
    };
    return {
      // 验证码是否CD
      codeCd: false,
      // CD长度
      long: 60,
      rules: {
        name: [{ validator: validateName, trigger: "blur", require: "true" }],
        phoneNumber: [
          { validator: validatePhoneNumber, trigger: "blur", require: "true" },
        ],
        code: [{ validator: checkCode, trigger: "blur", require: "true" }],
        question: [
          { validator: checkQuestion, trigger: "blur", require: "true" },
        ],
      },
      ruleForm: {
        name: "",
        phoneNumber: "",
        code: "",
        question: "",
      },
      questionTags: [
        { type: "info", label: "销售价格" },
        { type: "info", label: "产品方案" },
        { type: "info", label: "交付服务" },
        { type: "info", label: "运维服务" },
        { type: "info", label: "其他" },
      ],
      value1: "使命",
      optionsQues: [
        {
          label: "使命",
          value: "使命",
          msg: "感谢您对道可维斯的使命-用文档整合链接你我他提出宝贵意见!",
        },
        {
          label: "产品",
          value: "产品",
          msg: `感谢您对道可维斯的产品提出宝贵意见!`,
        },
        {
          label: "伙伴政策",
          value: "伙伴政策",
          msg: "感谢您对道可维斯的伙伴政策-一次分享三年分成提出宝贵意见!",
        },
        {
          label: "案例名录",
          value: "案例名录",
          msg: "感谢您对道可维斯的案例名录提出宝贵意见!",
        },
      ],
      isOpenVideo: false,
      isOpenQuestion: 0, //是否打开了问题
      isActive: false, //控制顶部与底部是否显示
      isShowquesetion: true,
      questionMessage:
        "感谢您对道可维斯的使命-用文档整合链接你我他提出宝贵意见!",
      checked: false,
      question: "", //提问的问题
      drawer: false, //提问框
      dialogVisible: false, //提问框1
      qrCodeDialogVisible: false,
      officialAccountDialogVisible: false, //公众号二维码
      qrCodesId: "",
      someList: [
        {
          html: "slide1",
          style: {
            background: "#fff",
          },
        },
        {
          html: "slide2",
          style: {
            background: "#4bbfc3",
          },
        },
        {
          html: "slide3",
          style: {
            background: "#7baabe",
          },
        },
      ],
      //Slider configuration [obj]
      options: {
        pagination: false,
        thresholdDistance: 100, // Sliding distance threshold
        thresholdTime: 300, // Sliding time threshold decision
        grabCursor: true, // Scratch style
        speed: 300, // Sliding speed
        currentPage: 0,
        // timingFunction: 'ease', // Sliding mode
        // loop: false, // Infinite loop
        // autoplay: 0 // Auto play[ms]
      },
      infor: "hw",
      isFirst: true,
      offsetWidth: document.body.offsetWidth, //页面宽度
      activeIndex: "", //导航index
      menu1: true,
      menu2: false,
      winUrl: "",
      code: "",
      page: "",
      appid: "",
      codeShow: false, //手机号登录出框
      codeShow1: false, //微信扫码登录弹框
      nickname: "", //昵名
      loginInterval: null, //登录轮询定时器
      systemPingtai: true,
      drawerNav: false, //抽屉
      direction: "rtl", //抽屉打开方向
      codeValidity: false,
			second: 180,
      qrcode:"",
      loginVisiable:false,
      secondTimer:null,
      keyInterval:null,
      qrcodeLoading:false
    };
  },
  beforeCreate() {
    window.location.href =
      window.location.href.split("#")[0] + "#" + this.$route.fullPath;
  },

  created() {
    //先判断有没有授权(判断地址栏code，有就-用户同意了授权，没有-没授权或者拒绝授权)
    // var str = this.$route.query.code; //此处使用的是history路由模式，hash这么拿不到。
    // if (str) {
    //   alert(str);
    //   //走后端接口(把code传给后台，让后台根据code获取openID与用户信息)
    //   // axios
    //   //   .get("/weChatUser/getUserInfoByTempCode?code=" + str)
    //   //   .then((res) => {
    //   //     if (res.data.code == 200) {
    //   //       //拿到了openID与用户信息
    //   //     } else {
    //   //       console.log("授权失败");
    //   //     }
    //   //   })
    //   //   .catch((err) => {});
    // } else {
    //   //没授权先授权
    //   window.location.href =
    //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx27e60c547a5ab724&redirect_uri=http://saas.beyondsky.love/internetmarketing_v0.2/&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    // }
    if (this.$route.query.isOpenDrawer) {
      this.openQuestion();
    }

    // this.$router.push({
    //   path: "/guidepage",
    // });

    // createScript(
    //   "http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"
    // );
    // !(function (a, b, c) {
    //   function d(a) {
    //     var c = "default";
    //     a.self_redirect === !0
    //       ? (c = "true")
    //       : a.self_redirect === !1 && (c = "false");
    //     var d = b.createElement("iframe"),
    //       e =
    //         "https://open.weixin.qq.com/connect/qrconnect?appid=" +
    //         a.appid +
    //         "&scope=" +
    //         a.scope +
    //         "&redirect_uri=" +
    //         a.redirect_uri +
    //         "&state=" +
    //         a.state +
    //         "&login_type=jssdk&self_redirect=" +
    //         c +
    //         "&styletype=" +
    //         (a.styletype || "") +
    //         "&sizetype=" +
    //         (a.sizetype || "") +
    //         "&bgcolor=" +
    //         (a.bgcolor || "") +
    //         "&rst=" +
    //         (a.rst || "");
    //     (e += a.style ? "&style=" + a.style : ""),
    //       (e += a.href ? "&href=" + a.href : ""),
    //       (d.src = e),
    //       (d.frameBorder = "0"),
    //       (d.allowTransparency = "true"),
    //       (d.sandbox = "allow-scripts allow-top-navigation allow-same-origin"), //修改的代码在此处，
    //       (d.scrolling = "no"),
    //       (d.width = "300px"),
    //       (d.height = "400px");
    //     var f = b.getElementById(a.id);
    //     (f.innerHTML = ""), f.appendChild(d);
    //   }
    //   a.WxLogin = d;
    // })(window, document);
  },
  watch: {
    $route(to, from) {
      // 重置问题
      this.question = "";
      let code = this.$route.query.code;
      if (code) {
        console.log(code);
        //通过URL的code，调用后端写的接口，接口才会返回用户登录的信息，和微信h5登录一样的模式
        this.LoginByScanCode(code);
      }
    },
  },
  methods: {
    handleNavClose(done) {
      done();
    },
    //调用是否关注公众号接口
    // GetWXUserInfo(){
    //   if (sessionStorage.getItem('code')) {
    //     param = {
    //       Code : sessionStorage.getItem('code'),
    //       ID:this.qrCodesId
    //     }
    //     this.$api.beyoudsky.GetWXUserInfo(param).them((res)=>{
    //       console.log(res.data.data);
    //     })
    //   } else {

    //   }
    // },
    //我要提问跳转
    routerToQuestion(data) {
      this.$router.push({
        path: "/question",
        query: { type: data },
      });
    },
    // 获取手机验证码
    handleCaptcha(form) {
      this.$refs[form].validateField("phoneNumber", async (valid) => {
        if (!valid) {
          // 获取验证码
          // captcha(this.ruleForm.phoneNum)
          let param = {
            mobile: this.ruleForm.phoneNumber,
          };
          this.$api.beyoudsky.SendVerify(param).then((res) => {
            if (res.data.errorCode == "-99") {
              alert(res.data.errorText);
            }
          });
          // 开启计时
          this.codeCd = true;
          const timer = setInterval(() => {
            this.long--;
            if (this.long <= 0) {
              this.long = 60;
              this.codeCd = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //调用GetWXUserInfo获取是否关注公众号
    // GetWXUserInfo() {
    //   let params = {
    //     Code: sessionStorage.getItem("code", code),
    //     TempID: this.qrCode,
    //   };
    //   this.$api.beyoudsky.GetWXUserInfo(params).then((res) => {
    //     console.log(1111111);
    //     console.log(res.data.data);
    //   });
    // },

    //根据微信openID获取用户信息
    GetUserPoByWX(data) {
      this.$api.beyoudsky.GetUserPoByWX(data).then((res) => {
        if (res.data.errorCode == "1") {
          console.log(res);
          sessionStorage.setItem("userDetialInfo", res.data.data);
        } else {
          alert(res.data.errorText);
        }
      });
    },
    // 截取url中的code方法
    getUrlCode() {
      var url = location.search;
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      console.log(theRequest);
      return theRequest;
    },

    //提交问题
    submitForm(formName) {
      // if (this.question == "") {
      //   alert("请输入您的问题再点击提交按钮！");
      // } else {
      //   let paramQues = new FormData();
      //   let param = {
      //     Content: this.question,
      //     WeChatID: "o-W8Kjx5jEW6dAhOfalrEXbtcfyo",
      //     Type: this.value1,
      //   };
      //   paramQues.append("InputJson", JSON.stringify(param));
      //   this.$api.beyoudsky.AddMsgHistory(paramQues).then((res) => {
      //     if (res.data.errorCode == "1") {
      //       //关闭提问框
      //       this.drawer = false;
      //       this.question = "";
      //       alert("提交成功！");
      //     }
      //   });
      // }
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          let paramQues = new FormData();
          let param = {
            Content: this.ruleForm.question, //问题
            WeChatID: userInfo.openid, //用户id
            Type: this.value1, //类型
            name: this.ruleForm.name, //姓名（需更改）
            phoneNumber: this.ruleForm.phoneNumber, //手机号（需更改）
            code: this.ruleForm.code, //验证码（需更改）
          };
          paramQues.append("InputJson", JSON.stringify(param));
          this.$api.beyoudsky.AddMsgHistory(paramQues).then((res) => {
            if (res.data.errorCode == "1") {
              //关闭提问框
              this.drawer = false;
              this.question = "";
              alert("提交成功！");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //手机登录
    submitFormRegister(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            PhoneNum: this.ruleForm.phoneNumber
              ? this.ruleForm.phoneNumber
              : "",
            Code: this.ruleForm.code ? this.ruleForm.code : "",
          };
          this.$api.beyoudsky.LoginByPhone(param).then((res) => {
            if (res.data.errorCode == "1") {
              console.log(res);
              sessionStorage.setItem("userDetialInfo", res.data.data);
              let data = JSON.parse(res.data.data);
              let userinfo = {
                openid: data.WeChatID ? data.WeChatID : "",
                nickname: data.NickName ? data.NickName : "",
              };
              sessionStorage.setItem("userInfo", JSON.stringify(userinfo));
              this.$message({
                message: "登录成功",
                type: "success",
              });
              this.codeShow = false;
            } else {
              alert(`${res.data.errorText}`);
            }
          });
        }
      });
    },
    //手机注册接口

    //打开注册页面

    openRegister() {
      if (this.$route.query.data == undefined) {
        let name = '官网' + "申请试用";
        this.$utils.AddAction(name)
      }
      // if (
      //   !sessionStorage.getItem("userInfo") ||
      //   sessionStorage.getItem("userInfo") == ""
      // ) {
      //   this.$message({
      //     message: "暂未登录，请先登录",
      //     type: "warning",
      //     duration: 1000,
      //     onClose: () => {
      //       this.scanlogin1();
      //     },
      //   });
      //   return;
      // }
      this.$router.push({
        path: "/register",
        query: { type: "申请试用", DemandType: "需求" },
      });
    },
    // 切换问题类型
    changeQuestion(val) {
      console.log(val);
      let data = this.optionsQues.filter((item) => {
        return item.value == val;
      });
      // 产品详情页动态展示产品名称
      // this.questionMessage = data[0].msg;
      if (this.$route.query.data && val == "产品") {
        // console.log(data[0].msg);
        this.questionMessage = data[0].msg.replace(
          "产品",
          `${this.$route.query.data}产品`
        );
      } else {
        this.questionMessage = data[0].msg;
      }
    },
    qidai() {
      alert("敬请期待!");
    },
    //获取所有的问题类型
    getQuestion() {
      if (this.isFirst) {
        this.$api.beyoudsky.GetQATypes().then((res) => {
          if (res.data.errorCode == "1") {
            let data = JSON.parse(res.data.data);
            this.questionTags = [];
            data.forEach((element) => {
              this.questionTags.push({
                type: element.Type,
                label: element.Type,
              });
            });

            this.optionsQues = [];
            data.forEach((element) => {
              this.optionsQues.push({
                label: element.Type,
                value: element.Type,
                msg: element.Msg,
              });
            });
            this.isFirst = false;
            console.log(this.optionsQues);
          }
        });
      }
    },
    scrollChange() {
      //   console.log("滚动高度", window.pageYOffset);
      //   console.log("距离顶部高度", this.$refs.menu.getBoundingClientRect().top);
      // console.log(this.$route.name);

      // 下载资料
      if (window.pageYOffset < 10 && window.pageYOffset > -10) {
        if (this.isFirst) {
          // this.$api.beyoudsky.GetQATypes().then((res) => {
          //   if (res.data.errorCode == "1") {
          //     let data = JSON.parse(res.data.data);
          //     this.optionsQues = [];
          //     data.forEach((element) => {
          //       this.optionsQues.push({
          //         label: element.Type,
          //         value: element.Type,
          //         msg: element.Msg,
          //       });
          //     });
          //     this.isFirst = false;
          //     console.log(this.optionsQues);
          //   }
          // });
          this.isFirst = false;
        }

        this.isActive = false;
      } else {
        this.isActive = true;
      }

      if (window.pageYOffset > 10) {
        this.menu1 = false;
        this.menu2 = true;
      } else {
        this.menu1 = true;
        this.menu2 = false;
      }

      if (this.$route.name == "download") {
        this.menu1 = false;
        this.menu2 = true;
        // console.log(111111,this.$refs.mobil_header.style)
        // this.isActive = true;
        // this.$refs.mobil_header.style.background = "#F5F5F6";
        // this.$refs.mobil_header.style.background = "#fff";
        // if (window.pageYOffset < 10 && window.pageYOffset > -10) {
        //   this.isActive = false;

        // this.$refs.mobil_header.style.background = "white";
        // } else {
        //   this.isActive = false;
        //   this.$refs.mobil_header.style.background = "#fff";
        // }
      }

      // else {
      //   if (window.pageYOffset < 10 && window.pageYOffset > -10) {
      //     this.isActive = false;
      //     this.$refs.mobil_header.style.background = "";
      //   } else {
      //     this.isActive = true;
      //     this.$refs.mobil_header.style.background = "#fff";
      //   }
      // }
      if (this.$route.name == "complain") {
        this.isActive = false;
      }
    },

    //打开产品页面
    openProductView(val) {
      this.$router.push({
        path: "/allProduct",
        query: {
          data: val,
        },
      });
    },
    //跳转至问题提问
    openQuestion() {
      // console.log(this.offsetWidth);
      // if (this.offsetWidth > 1024) {
      //   if (
      //     !sessionStorage.getItem("userInfo") ||
      //     sessionStorage.getItem("userInfo") == ""
      //   ) {
      //     this.$message({
      //       message: "暂未登录，请先登录",
      //       type: "warning",
      //       duration: 1000,
      //       onClose: () => {
      //         this.scanlogin1();
      //       },
      //     });
      //     return;
      //   }
      // }
      if (this.$route.query.data == undefined) {
        let name = '官网' + "我要提问";
        this.$utils.AddAction(name)
      }
      this.$router.push({
        path: "/register",
        query: { type: "营销", DemandType: "问题" },
      });
      // this.getQuestion();
      // // console.log('打开提问框');
      // if(this.offsetWidth<1024){
      //  this.drawer = true;
      // }else{
      //   this.dialogVisible = true
      // }
      // if (this.$route.query.data) {
      //   this.value1 = "产品";
      //   this.changeQuestion("产品");
      // }
    },
    //咨询热线
    hotline(type) {
      if (this.$route.query.data == undefined) {
        let name = '官网' + type;
        this.$utils.AddAction(name)
      }
    },
    //打开分成协议
    openAgreement() {
      this.$router.replace({ name: "sharingAgreement" });
    },
    //产品页的提示框
    questionOpen(data) {
      console.log(data);
      this.drawer = true;
      this.questionMessage = `感谢您对道可维斯的${data}产品提出宝贵意见!`;
      this.question = "";
    },
    //滑动时触发的事件
    slide(data) {
      switch (data.currentPage) {
        case 0:
          this.isShowquesetion = true;
          this.questionMessage =
            "感谢您对道可维斯的使命-用文档整合链接你我他提出宝贵意见!";
          this.question = "";
          break;
        case 1:
          this.isShowquesetion = true;
          this.questionMessage =
            "感谢您对道可维斯的伙伴政策-一次分享三年分成提出宝贵意见!";
          this.question = "";
          break;
        case 2:
          this.isShowquesetion = false;

          break;
        case 3:
          this.isShowquesetion = true;
          this.questionMessage = "感谢您对道可维斯的案例名录提出宝贵意见!";
          this.question = "";
          break;
      }
      console.log(data);
    },
    //手机号登录
    scanlogin() {
      //  alert('扫码登录，敬请期待')
      this.codeShow = true;
    },

    //打开微信扫码登录
    openScanCodeLogin() {
      // let data = Date.parse(new Date());
      this.qrCodeDialogVisible = true;
      // this.qrCodesId = data;
      // this.getCode()
      // this.loginInterval = setInterval(this.keepPool, 1000);
    },
    //微信扫描登录
    // scanlogin1(){
    //  alert('扫码登录，敬请期待')
    // this.codeShow1 = true
    // let url = "http://saas.beyondsky.love/internetmarketing_v0.2/#/guidepage"
    // let url = "http://192.168.110.14:8080/#/guidepage"
    // this.$nextTick(()=>{
    // var obj = new WxLogin({
    // self_redirect:true,
    // id:"login_container",
    // appid: "wx8e2d0530d1ba539c",
    // appid: "wx27e60c547a5ab724",
    //   scope: "snsapi_login",
    //   redirect_uri: encodeURIComponent(url),
    //   state: "login,0",
    //   style: "black",
    //   href: "",
    // });
    // console.log(obj);
    // })

    //打开公众号二维码弹窗
    scanlogin1() {
      //  alert('扫码登录，敬请期待')
      // this.officialAccountDialogVisible = true;
      this.loginVisiable = true
      this.createQrcode()
    },
    handleCloseLoginCode(){
      clearInterval(this.keyInterval)
      clearInterval(this.secondTimer)
      this.keyInterval = null
      this.secondTimer = null
      this.codeValidity = false
      this.loginVisiable = false
    },
    createQrcode() {
			// this.agreeShow = false;
			// this.isQrcode = true;
			this.qrcodeLoading = true;
      this.$api.beyoudsky.GetLoginQRCode().then((res) => {
				let url = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data;
				this.qrcode = url;
        this.codeValidity = true
        this.secondTimer = setInterval(() => {
					this.second--;
					if (this.second <= 0) {
						this.second = 180;
						this.codeCd = false;
						this.isQrcode = false;
						this.isLoseEfficacy = true;
						clearInterval(this.secondTimer);
					}
				}, 1000);
				// this.isLoseEfficacy = false;
				this.qrcodeLoading = false;
				this.CheckLoginInfoByTicket(res.data);
				this.keyInterval = setInterval(() => {
					this.CheckLoginInfoByTicket(res.data);
				}, 2000);
				setTimeout(() => {
					clearInterval(this.keyInterval);
					this.keyInterval = null;
				}, 1800000);
			})
		},
    CheckLoginInfoByTicket(Ticket){
      this.$api.beyoudsky.CheckLoginInfoByTicket({Ticket}).then((res) => {
        console.log(res);
        let data = res.data
        if (data.errorCode == '1') {
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('userInfo', JSON.stringify(data.data.userInfo));
            localStorage.setItem('e_userid', data.data.userInfo.id);
            this.nickname = data.data.userInfo.id
            clearInterval(this.keyInterval);
            this.keyInterval = null;
            // this.key = ''
            this.$message.success('登录成功');
            this.handleCloseLoginCode()
          }
      })
    },
    //轮询方法
    // keepPool() {
    //   console.log("轮询");
    //   let params = {
    //     TempID: this.qrCodesId,
    //   };
    //   this.$api.beyoudsky.RandomGetUserInfo(params).then((res) => {
    //     console.log(res);
    //     // let data = JSON.parse(res.data.data);
    //     let data = res.data.data;
    //     console.log(data.followStatus);
    //     if (res.data.errorCode == 1) {
    //       if (data.followStatus == "未关注公众号") {
    //       } else {
    //         window.clearInterval(this.loginInterval);
    //         console.log(data.UserInfo);
    //         this.qrCodeDialogVisible = false;
    //       }
    //     } else {
    //       window.clearInterval(this.loginInterval);
    //     }
    //   });
    // },

    // this.codeShow1 = true

    //打开二维码窗口
    qrCodeCreate() {
      let url = "http://docuvix.com/#/guidepage";
      // let url
      // if (this.systemPingtai) {
      //   url = "http://docuvix.com/#/guidepage"
      // }else{
      //   url = "http://docuvix.com/#/"+this.$router.currentRoute.fullPath
      // }
      // console.log(111111,url)
      this.$nextTick(() => {
        var obj = new WxLogin({
          self_redirect: false,
          id: "login_container",
          appid: "wx8e2d0530d1ba539c",
          scope: "snsapi_login",
          redirect_uri: encodeURIComponent(url),
          state: "login,0",
          style: "black",
          href: "",
        });
        console.log(obj);
        // this.LoginByScanCode(this.code)
        // this.code = this.getUrlCode().code;
        // const that=this
        // that.loginInterval=setInterval(that.LoginByScanCode,3000)
      });
      // console.log(this.qrCodesId);
      // let appID = "wx8e2d0530d1ba539c";
      // let url =
      //   "http://saas.beyondsky.love/internetmarketing_v0.2/#/authorization?qrCodesId=" +
      //   this.qrCodesId;
      // var qrcode = new QRCode(this.$refs.qrCode, {
      //   // text: 'xxxx', // 需要转换为二维码的内容
      //   text: url, // 需要转换为二维码的内容
      //   width: 100,
      //   height: 100,
      //   colorDark: "#000000",
      //   colorLight: "#ffffff",
      //   correctLevel: QRCode.CorrectLevel.L, //纠错等级
      // }); //频繁调用轮询方法
    },

    //dialog弹窗关闭回调
    qrCodeHandleClose() {
      this.qrCodeDialogVisible = false;
      // this.qrCode = "";
      // document.getElementById("qrCode").innerHTML = "";
      // window.clearInterval(this.loginInterval);
    },

    //扫码登录获取用户信息
    LoginByScanCode(code) {
      this.codeShow1 = false;
      let param = {
        Code: code,
      };
      this.$api.beyoudsky.LoginByScanCode(param).then((res) => {
        console.log(res);
        if (res.data.errorCode == "1") {
          console.log(res);
          sessionStorage.setItem("userDetialInfo", res.data.data);
          let data = JSON.parse(res.data.data);
          let userinfo = {
            openid: data.WeChatID ? data.WeChatID : "",
            nickname: data.NickName ? data.NickName : "",
          };
          sessionStorage.setItem("userInfo", JSON.stringify(userinfo));
          this.$message({
            message: "登录成功",
            type: "success",
          });
          this.codeShow = false;
          this.officialAccountDialogVisible = false;
          this.qrCodeDialogVisible = false;
          this.nickname = JSON.parse(
            sessionStorage.getItem("userInfo")
          ).nickname;
        } else {
          // alert(`${res.data.errorText}`);
          console.log(`${res.data.errorText}`);
        }
      });
    },
    onInit(data) {
      // console.log(data);
    },
    onTap(data) {
      // console.log(data);
    },
    //菜单切换
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      switch (key) {
        case 1:

          break;
        case 2:
          if (this.$route.query.data == undefined) {
            let name = '官网' + "关于我们";
            this.$utils.AddAction(name)
          }
          break;
        case 3:
          if (this.$route.query.data == undefined) {
            let name = '官网' + "创始人微博";
            this.$utils.AddAction(name)
          }
          break;

        default:
          break;
      }
      this.$refs.drawerNav.closeDrawer();
    },

    //urlencode
    urlencode(str) {
      str = (str + "").toString();
      return encodeURIComponent(str)
        .replace(/!/g, "%21")
        .replace(/'/g, "%27")
        .replace(/\(/g, "%28")
        .replace(/\)/g, "%29")
        .replace(/\*/g, "%2A")
        .replace(/%20/g, "+");
    },
    //伙伴成长计划
    chengzhang() {
      alert("正在建设中，敬请期待");
    },
  },
  components: {
    slider,
    slideritem,
    // GuidePage,
    //   product,
    //   companyProfile,
    //   agreement,
    //   caseCompany,
  },
  mounted() {
    // 滚动条的获取
    window.addEventListener("scroll", this.scrollChange, true);
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.src = "./assets/mobile/v0.1/js/header.js";
    // document.body.appendChild(script);
  },
  updated(){
    console.log(this.$router,this.$router.currentRoute.name);
    if (this.$router.currentRoute.name == 'register') {
      this.$refs.menu.style.height = '0'
      this.$refs.menu.style.overflow = 'hidden'
    }else{
      this.$refs.menu.style.height = 'auto'
      this.$refs.menu.style.overflow = 'auto'
    }
  }
};
</script>
<style lang="less">
@import "./assets/mobile/v0.1/css/new-common.css";
@import "./assets/mobile/v0.1/css/iconfont.css";
@import "./assets/mobile/v0.1/css/animate.min.css";
@import "./assets/mobile/v0.1/css/swiper.min.css";
@import "./assets/mobile/v0.1/css/jquery.mCustomScrollbar.css";
@import "./assets/mobile/v0.1/css/header.css";
@import "./assets/mobile/v0.1/css/footer.css";

@import "./assets/mobile/v02/css/mo_product_gather.css";
@import "./assets/mobile/v02/css/mo_product_pages.css";
</style>
<style lang="less" scoped>
// @import "./assets/mobile/v0.1/css/mo_score.css";
.officialAccount {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_code {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.menu {
  display: none;
}

.w1440 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.mobilheader_box {
  display: none;
}

.scanLogin {
  display: none;
}

.scanLogin1 {
  display: none;
}
 
@media only screen and (max-width: 1024px) {
  .mobilheader_box {
    display: block;
  }
}

.mobile-lv3nav .product-small:not(:first-child),
.mobile-lv3nav .product-high:not(:first-child),
.mobile-lv3nav .product-large:not(:first-child),
.mobile-lv3nav .product-pass:not(:first-child) {
  margin-top: 0.5rem;
}

.answer /deep/.el-collapse-item__header {
  font-size: 16px;
  font-weight: 700;
}

/deep/.el-collapse-item__content {
  font-size: 14px;
}

.isBlock {
  display: block;
  // transition: all 0.5s;
}

.EBC-intro-list .swiper-wrapper .swiper-slide {
  width: 50%;
  margin-bottom: 20px;

  .txt {
    padding: 0 6%;

    p {
      color: #575d6c;
      font-size: 14px;
      line-height: 20px;
    }

    h3 {
      margin-bottom: 6px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 12px;
    }
  }
}

.EBC-intro-list .swiper-wrapper {
  white-space: "normal";
  text-align: "left";
}

.EBC-intro-list .swiper-wrapper .swiper-slide {
  float: left;
  width: 25%;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .icon {
  max-width: 85px;
  margin: 0 auto 20px;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .icon img {
  max-width: 100%;
  transition: all 0.5s;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt {
  padding: 0 10% 10% 0;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt h3 {
  text-align: center;
  margin-bottom: 12px;
  color: #252b3a;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt p {
  color: #575d6c;
  line-height: 28px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .EBC-intro-list {
    overflow: hidden;
  }

  .EBC-intro-list .swiper-wrapper {
    margin-top: 30px;
    display: inline-block;
    flex-wrap: wrap;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide {
    width: 50%;
    margin-bottom: 20px;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .icon {
    margin-bottom: 10px;
    max-width: 48px;
    text-align: center;
    height: 37px;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .icon img {
    width: auto;
    max-height: 100%;
    object-fit: contain;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .txt h3 {
    margin-bottom: 6px;
    font-weight: bold;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .txt p {
    font-size: 14px;
    line-height: 20px;
  }

  .login_container iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

// @media screen and (max-width: 768px) {
//   .rlv-product .cont .swiper-wrapper {
//     display: inline-block !important;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide {
//     padding: 0;
//     width: 100%;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a .img {
//     width: 30px;
//     height: 30px;
//     position: absolute;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a .img img {
//     width: 100%;
//   }
//   .rlv-product .cont ul .epm_des {
//     font-size: 14px;
//   }
//   .swiper-relative {
//     overflow: hidden;
//     height: 430px;
//   }
//   .rlv-product .cont ul {
//     margin-left: 0;
//     margin-right: 0;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide {
//     padding: 0;
//     height: 200px;
//     margin-bottom: 10px;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a:hover .img {
//     opacity: 1;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a .text {
//     // top: 50px;
//     // padding: 15px 25px 15px;
//     top: 0;
//     // position: absolute;
//     z-index: 2;
//     width: 100%;
//     left: 0;
//     bottom: auto;
//     transition: all 0.5s ease;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a .text .epm_tit {
//     margin: 10px 0 20px 40px;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a:hover .text {
//     padding-top: 15px;
//     top: 0;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a {
//     padding: 20px 25px;
//     height: calc(100% - 6px);
//     display: block;
//     background-color: #fff;
//     position: relative;
//     overflow: hidden;
//     margin-bottom: 20px;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a:hover .epm_more {
//     margin-top: 10px;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a .epm_more {
//     width: 100px;
//     line-height: 32px;
//     font-size: 14px;
//     height: 32px;
//     margin-top: 10px;
//   }
//   .rlv-product .cont .swiper-wrapper .swiper-slide a .epm_more {
//     opacity: 1;
//     visibility: visible;
//   }
//   .rlv-product .swiper-pagination-bullet {
//     margin: 0 4px;
//   }
//   .slider-touch {
//     width: 100% !important;
//   }
//   .rlv-product .cont .swiper-wrapper {
//     display: inline-block;
//   }
// }
// .rlv-product .cont .swiper-wrapper {
//   display: inline-block !important;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide {
//   padding: 0;
//   width: 100%;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a .img {
//   width: 30px;
//   height: 30px;
//   position: absolute;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a .img img {
//   width: 100%;
// }
// .rlv-product .cont ul .epm_des {
//   font-size: 14px;
// }
// .swiper-relative {
//   overflow: hidden;
//   height: 430px;
// }
// .rlv-product .cont ul {
//   margin-left: 0;
//   margin-right: 0;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide {
//   padding: 0;
//   height: 200px;
//   margin-bottom: 10px;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a:hover .img {
//   opacity: 1;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a .text {
//   top: 0;
//   z-index: 2;
//   width: 100%;
//   left: 0;
//   bottom: auto;
//   transition: all 0.5s ease;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a .text .epm_tit {
//   margin: 10px 0 20px 40px;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a:hover .text {
//   padding-top: 15px;
//   top: 0;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a {
//   padding: 20px 25px;
//   height: calc(100% - 6px);
//   display: block;
//   background-color: #fff;
//   position: relative;
//   overflow: hidden;
//   margin-bottom: 20px;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a:hover .epm_more {
//   margin-top: 10px;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a .epm_more {
//   width: 100px;
//   line-height: 32px;
//   font-size: 14px;
//   height: 32px;
//   margin-top: 10px;
// }
// .rlv-product .cont .swiper-wrapper .swiper-slide a .epm_more {
//   opacity: 1;
//   visibility: visible;
// }
// .rlv-product .swiper-pagination-bullet {
//   margin: 0 4px;
// }
// .slider-touch {
//   width: 100% !important;
// }
// .rlv-product .cont .swiper-wrapper {
//   display: inline-block;
// }
// .rlv-product .cont .slider-touch {
//   width: 100% !important;
// }
// .slider-item {
//   display: inline-block;
// }

.slider-pagination-bullet-active {
  color: #000 !important;
}

.video .closeVideo {
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 66;
  cursor: pointer;
}

#guidepage {
  .drawer {
    .submitQuestion {
      width: 100%;
    }

    /deep/.el-drawer__open .el-drawer.btt {
      border-radius: 10px 10px 0 0;
      padding: 23px 10px 10px 10px;
      // height: 65% !important;
      height: 250px !important;
    }

    .el-drawer__body {
      .tag-group {
        box-sizing: border-box;
        text-align: left;

        .el-button {
          text-align: center;
          width: 45%;
          height: 45px;
          margin: 0 15px 10px 0;
          box-sizing: border-box;
          line-height: 45px;
          font-size: 20px;
          color: #666;
          padding: 0;
          font-size: 14px;
          // color: #666;
          padding: 0;
        }

        // .el-tag {
        //   text-align: center;
        //   width: 45%;
        //   height: 45px;
        //   margin: 0 15px 10px 0;
        //   box-sizing: border-box;
        //   line-height: 45px;
        //   font-size: 16px;
        //   color: #666;
        // }
      }
    }

    .msg {
      line-height: 30px;
      font-size: 18px;
      margin-bottom: 10px;

      .el-select {
        width: 120px;
        height: 30px;

        /deep/.el-input--suffix .el-input__inner {
          height: 30px;
        }

        /deep/ .el-input__icon {
          line-height: 30px;
        }
      }
    }

    .tip {
      line-height: 25px;
      font-size: 14px;
    }

    .input {
      // text-align: center;
      // height: calc(100% - 120px);
      padding: 5px 0px;

      /deep/.el-textarea {
        width: calc(100% - 68px - 10px);
        margin-right: 10px;
        // box-sizing: border-box;
        // min-height: 150px !important;

        .el-textarea__inner {
          padding: 8px 15px;
          resize: none;
          // height: 60px;
          box-sizing: border-box;
          min-height: 40px !important;
          // max-height: 150px !important;
        }
      }

      button {
        margin-top: 10px;
        width: 68px;
        // height: 46px;
      }
    }
  }

  .drawer1 {
    /deep/.el-dialog__body {
      height: 30vh !important;

      .msg {
        margin-bottom: 20px;
      }

      .tip {
        margin-bottom: 20px;
      }
    }

    .submit1 {
      margin-top: 20px;
    }
  }
}
// @media screen and(min-width:1024px){
//设备屏幕宽度最小值1024px
@media screen and (min-device-width: 1024px) {
  #guidepage {
    position: relative;
    .isBlock {
      // display: block;
      display: none;
    }

    .mobilheader_box .mobil_header:hover {
      background: #fff;

      .scanLogin,
      .scanLogin1,
      .el-menu-item {
        color: #000 !important;
      }

      .logo {
        display: none;
      }

      .imghover {
        display: block !important;
      }
    }

    .mobilheader_box .mobil_header .logo-box img {
      margin-top: 9px !important;
      width: 140px;
    }

    .scanLogin {
      display: block;
      position: absolute;
      display: inline-block;
      width: 80px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      top: 25px;
      right: 280px;
      color: #fff;
      font-size: 400 !important;
    }

    .scanLogin1 {
      display: block;
      position: absolute;
      display: inline-block;
      width: 200px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      top: 25px;
      right: 150px;
      color: #fff;
      font-size: 400 !important;
    }

    .scanLogin:hover {
      cursor: pointer;
    }

    .scanLogin1:hover {
      cursor: pointer;
    }

    .menu {
      display: block;
      width: 70%;
      margin: 0 auto;
      height: 100%;

      .el-menu {
        border: 0;
        background: none;
        display: flex;
        justify-content: flex-start;
        // justify-content: center;
        margin: 5px auto;
        font-weight: normal !important;

        .initial {
          color: #fff !important;
        }
      }

      .el-menu-item {
        margin-bottom: 5px;
        color: black;
        transition: 0.1s ease-out;
        // width: 1;
        height: 100%;
        padding: 0 27px;
        text-align: center;
        font-size: 18px !important;
        // margin-top: 4px;
        font-weight: 500 !important;

        a {
          font-weight: 400 !important;
        }
      }

      .is-active {
        color: black !important;
      }

      .el-menu-item:focus {
        background: none;
      }

      // .el-menu-item::after{
      //   position: absolute;
      //   transition: .2s ease-in;
      //   content: "";
      //   bottom: 0;
      //   left: 0;
      //   height: 2px;
      //   width: 100%;
      //   background: #2788EE;
      // }
      .el-menu-item:hover {
        background: none !important;
        border-bottom: 2px solid #2788ee !important;
        // color: #000;
        // font-size: 25px!important;
      }
    }

    .features-box {
      top: 22%;
    }

    .mobil_footer {
      padding: 0;

      .footer-right {
        .footer-link-bottom {
          padding: 20px;
          font-size: 16px !important;

          .footer-link-bottombq {
            font-size: 14px;
          }

          .footer-link-mobil {
            border: 0px;
            font-size: 14px;
            display: inline-block;
          }
        }
      }
    }

    .index-yiwen {
      padding: 0 151px !important;

      .wrapper {
        width: 100% !important;
        text-align: center;

        .info {
          padding: 30px 0 !important;
          margin: 20px 0 !important;

          .fnt_20 {
            margin: 0 auto;
            max-width: 58%;
          }

          .fnt_32 {
            font-size: 32px;
            font-weight: 800;
            text-align: center;
          }
        }
      }
    }

    .tip-right {
      position: fixed;
      padding: 10px 0 10px 0;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      width: 84px;
      height: 145px;
      background: #2788ee;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      right: 0;
      bottom: 12%;
      z-index: 3;
      transition: 0.4s ease-out;

      .Bottom-remain-left,
      .Bottom-remain-right {
        height: 49%;
        width: 80%;
        margin: 0 auto;
        // margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-bottom: 1px solid #fff;
      }

      // .Bottom-remain-left{
      // padding-bottom: 10px;
      // margin-bottom: 5px;
      // }
      .Bottom-remain-left::after {
        // content: '';
        position: absolute;
        width: 0;
        height: 1px;
        bottom: -5px;
        // background-color: #fff;
      }

      .Bottom-remain-bottom {
        height: 49%;
        width: 80%;
        margin: 0 auto;
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      img {
        width: 20%;
      }

      .connet-us {
        border-radius: 0px;
        border-radius: 4px;
        transition: 0.4s ease-out;
        position: fixed;
        z-index: 1;
        width: 300px;
        height: 300px;
        right: -300px;
        // top: 440px;
        bottom: 0;
        opacity: 0;
        // box-shadow: 0 0 15px #333;
        background: #fff;

        .title {
          width: 100%;
          height: 40%;
          background: url("../../assets/images/phone_bg.png");
          background-size: 100%;
          display: flex;
          color: #fff;
          font-size: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .detial {
          width: 100%;
          height: 59%;
          text-align: center;
          padding: 10px 0;

          .erweima {
            img {
              width: 50%;
            }
          }
        }
      }

      .Bottom-remain-bottom:hover {
        .connet-us {
          right: 0px;
          opacity: 1;
          box-shadow: 0 0 15px #333;
        }
      }
    }

    // .tip-right:hover{
    //   right: 0;
    // }

    // .index-yiwen .wrapper .info{
    //   padding: 50px 3%!important;
    // }
  }

  .drawer1 {
    button {
      font-size: 20px !important;
    }
  }
}
</style>
